<script>
import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
    export default {
        page: {
            title: "Star Citizen Mining Preise",
            items: [
            {
                text: "XIVDATA",
                href: "/"
            },
            {
                text: "Fina's Privat Kram",
                href: "/"
            },
            {
                text: "Star Citizen Mining Preise",
                active: true
            }
        ],
        meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader
  },
    }
</script>
<template>
<Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="mt-0 header-title">Star Citizen: Mining Information - Schiffe</h4>
                    <p class="text-muted m-b-30">Kleine Infosammlung, was welches Material bringt und worin man es findet.</p>
                    <table class="table table-borderless table-dark">
                        <thead>
                            <tr>
                                <th>Ressource</th>
                                <th>Depot</th>
                                <th>aUEC pro Unit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-success">
                                <td>Quantainium</td>
                                <td>Unbekannt</td>
                                <td>88,09</td>
                            </tr>
                            <tr class="bg-success">
                                <td>Bexalite</td>
                                <td>Felsic, Obsidian</td>
                                <td>40,48</td>
                            </tr>
                            <tr class="bg-success">
                                <td>Taranite</td>
                                <td>Atacamite, Igneous</td>
                                <td>32,45</td>
                            </tr>
                            <tr class="bg-success">
                                <td>Borase</td>
                                <td>Asteroid (E-Type)</td>
                                <td>32,44</td>
                            </tr>
                            <tr class="bg-success">
                                <td>Laranite</td>
                                <td>Granite</td>
                                <td>30,25</td>
                            </tr>
                            <tr class="bg-success">
                                <td>Agricium</td>
                                <td>Atacamite, Gneiss, Granite, Shale</td>
                                <td>26,88</td>
                            </tr>
                            <tr>
                                <td>Hephaestanite</td>
                                <td>Felsic, Obsidian</td>
                                <td>14,64</td>
                            </tr>
                            <tr>
                                <td>Titanium</td>
                                <td>Asteroid (P-Type), Igneous, Shale</td>
                                <td>8,67</td>
                            </tr>
                            <tr>
                                <td>Diamond</td>
                                <td>Atacamite, Gneiss, Granite, Quartzite</td>
                                <td>7,17</td>
                            </tr>
                            <tr class="bg-warning">
                                <td>Gold</td>
                                <td>Asteroid (E-Type), Igneous, Quartzite, Shale</td>
                                <td>6,42</td>
                            </tr>
                            <tr class="bg-warning">
                                <td>Copper</td>
                                <td>Asteroid (E-Type), Igneous</td>
                                <td>5,69</td>
                            </tr>
                            <tr class="bg-danger">
                                <td>Beryl</td>
                                <td>Quartzite</td>
                                <td>4,30</td>
                            </tr>
                            <tr class="bg-danger">
                                <td>Tungsten</td>
                                <td>Asteroid (M-Type), Granite, Igneous</td>
                                <td>3,99</td>
                            </tr>
                            <tr class="bg-danger">
                                <td>Corundum</td>
                                <td>Asteroid (E-/M-Type), Granite, Obsidian</td>
                                <td>2,65</td>
                            </tr>
                            <tr class="bg-danger">
                                <td>Quartz</td>
                                <td>Felsic, Quartzite</td>
                                <td>1,51</td>
                            </tr>
                            <tr class="bg-danger">
                                <td>Aluminium</td>
                                <td>Asteroid (P-/M-Type), Shale, Gneiss</td>
                                <td>1,27</td>
                            </tr>
                            <tr class="bg-danger">
                                <td>Inert Material</td>
                                <td>überall</td>
                                <td>0,02</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</Layout>
</template>